@font-face {
	font-family: "ng-icon";
	src: url("ng-icon.eot?99268aebf8439a60057abcbd60b6a8cd?#iefix") format("embedded-opentype"),
url("ng-icon.woff?99268aebf8439a60057abcbd60b6a8cd") format("woff"),
url("ng-icon.ttf?99268aebf8439a60057abcbd60b6a8cd") format("truetype");
}

.icon {
	line-height: 1;
}

.icon:before {
	font-family: ng-icon !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.ng-icon-18-plus:before {
	content: "\f101";
}
.ng-icon-OnTheMoney:before {
	content: "\f102";
}
.ng-icon-RTG-landing:before {
	content: "\f103";
}
.ng-icon-all-sports-filter:before {
	content: "\f104";
}
.ng-icon-americanfootball:before {
	content: "\f105";
}
.ng-icon-angle-down:before {
	content: "\f106";
}
.ng-icon-angle-left:before {
	content: "\f107";
}
.ng-icon-angle-right:before {
	content: "\f108";
}
.ng-icon-angle-up:before {
	content: "\f109";
}
.ng-icon-arrow-bullseye:before {
	content: "\f10a";
}
.ng-icon-arrow-circle-down:before {
	content: "\f10b";
}
.ng-icon-arrow-circle-left:before {
	content: "\f10c";
}
.ng-icon-arrow-circle-right:before {
	content: "\f10d";
}
.ng-icon-arrow-circle-up:before {
	content: "\f10e";
}
.ng-icon-arrow-down:before {
	content: "\f10f";
}
.ng-icon-arrow-from-bottom:before {
	content: "\f110";
}
.ng-icon-arrow-from-left:before {
	content: "\f111";
}
.ng-icon-arrow-from-right:before {
	content: "\f112";
}
.ng-icon-arrow-from-top:before {
	content: "\f113";
}
.ng-icon-arrow-left:before {
	content: "\f114";
}
.ng-icon-arrow-right:before {
	content: "\f115";
}
.ng-icon-award:before {
	content: "\f116";
}
.ng-icon-axe-battle:before {
	content: "\f117";
}
.ng-icon-basket-filled:before {
	content: "\f118";
}
.ng-icon-basket-outline:before {
	content: "\f119";
}
.ng-icon-basketball:before {
	content: "\f11a";
}
.ng-icon-be-gamble-aware:before {
	content: "\f11b";
}
.ng-icon-bell:before {
	content: "\f11c";
}
.ng-icon-big-check:before {
	content: "\f11d";
}
.ng-icon-big5:before {
	content: "\f11e";
}
.ng-icon-big5Big:before {
	content: "\f11f";
}
.ng-icon-big5Small:before {
	content: "\f120";
}
.ng-icon-big5_new_dashboard:before {
	content: "\f121";
}
.ng-icon-block:before {
	content: "\f122";
}
.ng-icon-bolt:before {
	content: "\f123";
}
.ng-icon-bullseye-arrow:before {
	content: "\f124";
}
.ng-icon-calendar-full:before {
	content: "\f125";
}
.ng-icon-calendar-times:before {
	content: "\f126";
}
.ng-icon-cart-unpaid:before {
	content: "\f127";
}
.ng-icon-check-circle:before {
	content: "\f128";
}
.ng-icon-check:before {
	content: "\f129";
}
.ng-icon-chevron-circle-down:before {
	content: "\f12a";
}
.ng-icon-chevron-circle-left:before {
	content: "\f12b";
}
.ng-icon-chevron-circle-right:before {
	content: "\f12c";
}
.ng-icon-chevron-circle-up:before {
	content: "\f12d";
}
.ng-icon-chevron-double-down:before {
	content: "\f12e";
}
.ng-icon-chevron-double-left:before {
	content: "\f12f";
}
.ng-icon-chevron-double-right:before {
	content: "\f130";
}
.ng-icon-chevron-double-up:before {
	content: "\f131";
}
.ng-icon-chevron-down:before {
	content: "\f132";
}
.ng-icon-chevron-left:before {
	content: "\f133";
}
.ng-icon-chevron-right:before {
	content: "\f134";
}
.ng-icon-chevron-square-down:before {
	content: "\f135";
}
.ng-icon-chevron-square-left:before {
	content: "\f136";
}
.ng-icon-chevron-square-right:before {
	content: "\f137";
}
.ng-icon-chevron-square-up:before {
	content: "\f138";
}
.ng-icon-chevron-up:before {
	content: "\f139";
}
.ng-icon-circle:before {
	content: "\f13a";
}
.ng-icon-clipboard-list:before {
	content: "\f13b";
}
.ng-icon-clock-history:before {
	content: "\f13c";
}
.ng-icon-clock-timer:before {
	content: "\f13d";
}
.ng-icon-clock:before {
	content: "\f13e";
}
.ng-icon-close:before {
	content: "\f13f";
}
.ng-icon-cloud-upload:before {
	content: "\f140";
}
.ng-icon-cog:before {
	content: "\f141";
}
.ng-icon-cogs:before {
	content: "\f142";
}
.ng-icon-coin:before {
	content: "\f143";
}
.ng-icon-coins-filled:before {
	content: "\f144";
}
.ng-icon-coins:before {
	content: "\f145";
}
.ng-icon-comment:before {
	content: "\f146";
}
.ng-icon-corner-flag:before {
	content: "\f147";
}
.ng-icon-credit-card-blank:before {
	content: "\f148";
}
.ng-icon-credit-card-front:before {
	content: "\f149";
}
.ng-icon-credit-card:before {
	content: "\f14a";
}
.ng-icon-cross-close-thin:before {
	content: "\f14b";
}
.ng-icon-cross-close:before {
	content: "\f14c";
}
.ng-icon-cross:before {
	content: "\f14d";
}
.ng-icon-crow:before {
	content: "\f14e";
}
.ng-icon-disallowed:before {
	content: "\f14f";
}
.ng-icon-document:before {
	content: "\f150";
}
.ng-icon-dreamteam-landing:before {
	content: "\f151";
}
.ng-icon-edit:before {
	content: "\f152";
}
.ng-icon-empty-selection:before {
	content: "\f153";
}
.ng-icon-envelope:before {
	content: "\f154";
}
.ng-icon-exclamation-circle-filled:before {
	content: "\f155";
}
.ng-icon-exclamation-circle:before {
	content: "\f156";
}
.ng-icon-exclamation-filled-alt:before {
	content: "\f157";
}
.ng-icon-exclamation-square:before {
	content: "\f158";
}
.ng-icon-exclamation-triangle:before {
	content: "\f159";
}
.ng-icon-exclamation:before {
	content: "\f15a";
}
.ng-icon-external-link-alt:before {
	content: "\f15b";
}
.ng-icon-external-link-square-alt:before {
	content: "\f15c";
}
.ng-icon-external-link-square:before {
	content: "\f15d";
}
.ng-icon-external-link:before {
	content: "\f15e";
}
.ng-icon-eye-dropper:before {
	content: "\f15f";
}
.ng-icon-eye-evil:before {
	content: "\f160";
}
.ng-icon-eye-slash:before {
	content: "\f161";
}
.ng-icon-eye:before {
	content: "\f162";
}
.ng-icon-facebook-logo:before {
	content: "\f163";
}
.ng-icon-fan-table:before {
	content: "\f164";
}
.ng-icon-fan:before {
	content: "\f165";
}
.ng-icon-fib:before {
	content: "\f166";
}
.ng-icon-fib_old:before {
	content: "\f167";
}
.ng-icon-filled-check:before {
	content: "\f168";
}
.ng-icon-filled-info:before {
	content: "\f169";
}
.ng-icon-fitzdares-landing:before {
	content: "\f16a";
}
.ng-icon-flag:before {
	content: "\f16b";
}
.ng-icon-football:before {
	content: "\f16c";
}
.ng-icon-foul:before {
	content: "\f16d";
}
.ng-icon-fsb-allsports:before {
	content: "\f16e";
}
.ng-icon-fsb-basketball:before {
	content: "\f16f";
}
.ng-icon-fsb-golf:before {
	content: "\f170";
}
.ng-icon-fsb-racing:before {
	content: "\f171";
}
.ng-icon-futbol:before {
	content: "\f172";
}
.ng-icon-gambling-comm:before {
	content: "\f173";
}
.ng-icon-gamstop:before {
	content: "\f174";
}
.ng-icon-golf-ball:before {
	content: "\f175";
}
.ng-icon-golf:before {
	content: "\f176";
}
.ng-icon-group-alt:before {
	content: "\f177";
}
.ng-icon-group-stats:before {
	content: "\f178";
}
.ng-icon-group:before {
	content: "\f179";
}
.ng-icon-heart-filled:before {
	content: "\f17a";
}
.ng-icon-heart-loss:before {
	content: "\f17b";
}
.ng-icon-heart:before {
	content: "\f17c";
}
.ng-icon-home-filled:before {
	content: "\f17d";
}
.ng-icon-home-outline:before {
	content: "\f17e";
}
.ng-icon-hoop:before {
	content: "\f17f";
}
.ng-icon-horse-racing-icon-new:before {
	content: "\f180";
}
.ng-icon-horse-racing-icon:before {
	content: "\f181";
}
.ng-icon-horse-union:before {
	content: "\f182";
}
.ng-icon-horse_racing_lobby_big:before {
	content: "\f183";
}
.ng-icon-horse_racing_lobby_small:before {
	content: "\f184";
}
.ng-icon-horseracing:before {
	content: "\f185";
}
.ng-icon-info-circle:before {
	content: "\f186";
}
.ng-icon-info-filled:before {
	content: "\f187";
}
.ng-icon-instagram-logo:before {
	content: "\f188";
}
.ng-icon-key:before {
	content: "\f189";
}
.ng-icon-language:before {
	content: "\f18a";
}
.ng-icon-lb_down:before {
	content: "\f18b";
}
.ng-icon-lb_up:before {
	content: "\f18c";
}
.ng-icon-list-ol:before {
	content: "\f18d";
}
.ng-icon-list-ul:before {
	content: "\f18e";
}
.ng-icon-list:before {
	content: "\f18f";
}
.ng-icon-lobby-info:before {
	content: "\f190";
}
.ng-icon-lock-alt:before {
	content: "\f191";
}
.ng-icon-lock-filled-alt:before {
	content: "\f192";
}
.ng-icon-lock-filled:before {
	content: "\f193";
}
.ng-icon-lock:before {
	content: "\f194";
}
.ng-icon-mail-bulk:before {
	content: "\f195";
}
.ng-icon-map-signs:before {
	content: "\f196";
}
.ng-icon-mastercard-logo:before {
	content: "\f197";
}
.ng-icon-minus-alt:before {
	content: "\f198";
}
.ng-icon-minus:before {
	content: "\f199";
}
.ng-icon-mygames-filled:before {
	content: "\f19a";
}
.ng-icon-mygames-outline:before {
	content: "\f19b";
}
.ng-icon-networkgaming-landing:before {
	content: "\f19c";
}
.ng-icon-nr_error:before {
	content: "\f19d";
}
.ng-icon-nudge-big:before {
	content: "\f19e";
}
.ng-icon-nudge-clock:before {
	content: "\f19f";
}
.ng-icon-nudge-left:before {
	content: "\f1a0";
}
.ng-icon-nudge-marker:before {
	content: "\f1a1";
}
.ng-icon-nudge-right:before {
	content: "\f1a2";
}
.ng-icon-nudge:before {
	content: "\f1a3";
}
.ng-icon-offside:before {
	content: "\f1a4";
}
.ng-icon-otm-trophy-full:before {
	content: "\f1a5";
}
.ng-icon-otm-trophy:before {
	content: "\f1a6";
}
.ng-icon-paper-plane:before {
	content: "\f1a7";
}
.ng-icon-pen:before {
	content: "\f1a8";
}
.ng-icon-pencil-alt:before {
	content: "\f1a9";
}
.ng-icon-pencil:before {
	content: "\f1aa";
}
.ng-icon-pick6Big:before {
	content: "\f1ab";
}
.ng-icon-pick6Small:before {
	content: "\f1ac";
}
.ng-icon-play:before {
	content: "\f1ad";
}
.ng-icon-player-exclude:before {
	content: "\f1ae";
}
.ng-icon-plus-alt:before {
	content: "\f1af";
}
.ng-icon-plus:before {
	content: "\f1b0";
}
.ng-icon-power-off:before {
	content: "\f1b1";
}
.ng-icon-price_radio:before {
	content: "\f1b2";
}
.ng-icon-question-circle:before {
	content: "\f1b3";
}
.ng-icon-question-filled:before {
	content: "\f1b4";
}
.ng-icon-question-mark:before {
	content: "\f1b5";
}
.ng-icon-question-square:before {
	content: "\f1b6";
}
.ng-icon-question:before {
	content: "\f1b7";
}
.ng-icon-radio:before {
	content: "\f1b8";
}
.ng-icon-rebound:before {
	content: "\f1b9";
}
.ng-icon-rebuy:before {
	content: "\f1ba";
}
.ng-icon-receipt:before {
	content: "\f1bb";
}
.ng-icon-red-card:before {
	content: "\f1bc";
}
.ng-icon-repeat-alt:before {
	content: "\f1bd";
}
.ng-icon-repeat:before {
	content: "\f1be";
}
.ng-icon-reply-all:before {
	content: "\f1bf";
}
.ng-icon-search:before {
	content: "\f1c0";
}
.ng-icon-settings:before {
	content: "\f1c1";
}
.ng-icon-sgsBig:before {
	content: "\f1c2";
}
.ng-icon-sgsSmall:before {
	content: "\f1c3";
}
.ng-icon-sgsbasketball:before {
	content: "\f1c4";
}
.ng-icon-share-link:before {
	content: "\f1c5";
}
.ng-icon-shirts_fib:before {
	content: "\f1c6";
}
.ng-icon-shot:before {
	content: "\f1c7";
}
.ng-icon-sign-in-alt:before {
	content: "\f1c8";
}
.ng-icon-sign-in:before {
	content: "\f1c9";
}
.ng-icon-sign-out:before {
	content: "\f1ca";
}
.ng-icon-skull-alt:before {
	content: "\f1cb";
}
.ng-icon-skull-cow:before {
	content: "\f1cc";
}
.ng-icon-skull-crossbones:before {
	content: "\f1cd";
}
.ng-icon-skull-filled:before {
	content: "\f1ce";
}
.ng-icon-skull:before {
	content: "\f1cf";
}
.ng-icon-spinner:before {
	content: "\f1d0";
}
.ng-icon-star-excalmation:before {
	content: "\f1d1";
}
.ng-icon-star-of-david:before {
	content: "\f1d2";
}
.ng-icon-star-outline:before {
	content: "\f1d3";
}
.ng-icon-star-solid:before {
	content: "\f1d4";
}
.ng-icon-star-subtract-thin:before {
	content: "\f1d5";
}
.ng-icon-star-subtract:before {
	content: "\f1d6";
}
.ng-icon-star:before {
	content: "\f1d7";
}
.ng-icon-stats-graph:before {
	content: "\f1d8";
}
.ng-icon-steal:before {
	content: "\f1d9";
}
.ng-icon-stop:before {
	content: "\f1da";
}
.ng-icon-substitution:before {
	content: "\f1db";
}
.ng-icon-survivorBig:before {
	content: "\f1dc";
}
.ng-icon-survivorSmall:before {
	content: "\f1dd";
}
.ng-icon-survivor_new_dashboard:before {
	content: "\f1de";
}
.ng-icon-tachometer-alt-average:before {
	content: "\f1df";
}
.ng-icon-tachometer-alt-fast:before {
	content: "\f1e0";
}
.ng-icon-tachometer-alt-fastest:before {
	content: "\f1e1";
}
.ng-icon-tachometer-alt-slow:before {
	content: "\f1e2";
}
.ng-icon-tachometer-alt-slowest:before {
	content: "\f1e3";
}
.ng-icon-tachometer-alt:before {
	content: "\f1e4";
}
.ng-icon-tachometer-average:before {
	content: "\f1e5";
}
.ng-icon-tachometer-fast:before {
	content: "\f1e6";
}
.ng-icon-tachometer-fastest:before {
	content: "\f1e7";
}
.ng-icon-tachometer-slow:before {
	content: "\f1e8";
}
.ng-icon-tachometer-slowest:before {
	content: "\f1e9";
}
.ng-icon-talksport-games-landing:before {
	content: "\f1ea";
}
.ng-icon-team-letter-a:before {
	content: "\f1eb";
}
.ng-icon-team-letter-b:before {
	content: "\f1ec";
}
.ng-icon-team-letter-c:before {
	content: "\f1ed";
}
.ng-icon-team-letter-d:before {
	content: "\f1ee";
}
.ng-icon-team-letter-e:before {
	content: "\f1ef";
}
.ng-icon-team-letter-f:before {
	content: "\f1f0";
}
.ng-icon-team-letter-g:before {
	content: "\f1f1";
}
.ng-icon-team-letter-h:before {
	content: "\f1f2";
}
.ng-icon-team-letter-i:before {
	content: "\f1f3";
}
.ng-icon-team-letter-j:before {
	content: "\f1f4";
}
.ng-icon-team-letter-k:before {
	content: "\f1f5";
}
.ng-icon-team-letter-l:before {
	content: "\f1f6";
}
.ng-icon-team-letter-m:before {
	content: "\f1f7";
}
.ng-icon-team-letter-n:before {
	content: "\f1f8";
}
.ng-icon-team-letter-o:before {
	content: "\f1f9";
}
.ng-icon-team-letter-p:before {
	content: "\f1fa";
}
.ng-icon-team-letter-q:before {
	content: "\f1fb";
}
.ng-icon-team-letter-r:before {
	content: "\f1fc";
}
.ng-icon-team-letter-s:before {
	content: "\f1fd";
}
.ng-icon-team-letter-t:before {
	content: "\f1fe";
}
.ng-icon-team-letter-u:before {
	content: "\f1ff";
}
.ng-icon-team-letter-v:before {
	content: "\f200";
}
.ng-icon-team-letter-w:before {
	content: "\f201";
}
.ng-icon-team-letter-x:before {
	content: "\f202";
}
.ng-icon-team-letter-y:before {
	content: "\f203";
}
.ng-icon-team-letter-z:before {
	content: "\f204";
}
.ng-icon-ticket-alt:before {
	content: "\f205";
}
.ng-icon-ticket:before {
	content: "\f206";
}
.ng-icon-timer:before {
	content: "\f207";
}
.ng-icon-times-circle:before {
	content: "\f208";
}
.ng-icon-times-hexagon:before {
	content: "\f209";
}
.ng-icon-times-octagon:before {
	content: "\f20a";
}
.ng-icon-times-square:before {
	content: "\f20b";
}
.ng-icon-times:before {
	content: "\f20c";
}
.ng-icon-trash-alt-dark:before {
	content: "\f20d";
}
.ng-icon-trash-alt:before {
	content: "\f20e";
}
.ng-icon-trash:before {
	content: "\f20f";
}
.ng-icon-trophy:before {
	content: "\f210";
}
.ng-icon-turnover:before {
	content: "\f211";
}
.ng-icon-tv-retro:before {
	content: "\f212";
}
.ng-icon-tv:before {
	content: "\f213";
}
.ng-icon-twitter-logo:before {
	content: "\f214";
}
.ng-icon-typewriter:before {
	content: "\f215";
}
.ng-icon-ufo-beam:before {
	content: "\f216";
}
.ng-icon-ufo:before {
	content: "\f217";
}
.ng-icon-union:before {
	content: "\f218";
}
.ng-icon-unlock-alt:before {
	content: "\f219";
}
.ng-icon-unlock:before {
	content: "\f21a";
}
.ng-icon-unpicked-picks:before {
	content: "\f21b";
}
.ng-icon-user-alt:before {
	content: "\f21c";
}
.ng-icon-user-check:before {
	content: "\f21d";
}
.ng-icon-user-circle:before {
	content: "\f21e";
}
.ng-icon-user-crown:before {
	content: "\f21f";
}
.ng-icon-user-edit:before {
	content: "\f220";
}
.ng-icon-user-friends:before {
	content: "\f221";
}
.ng-icon-user-plus:before {
	content: "\f222";
}
.ng-icon-user:before {
	content: "\f223";
}
.ng-icon-users-crown-filled:before {
	content: "\f224";
}
.ng-icon-users-crown:before {
	content: "\f225";
}
.ng-icon-users-outline:before {
	content: "\f226";
}
.ng-icon-users:before {
	content: "\f227";
}
.ng-icon-visa-logo:before {
	content: "\f228";
}
.ng-icon-wallet:before {
	content: "\f229";
}
.ng-icon-website:before {
	content: "\f22a";
}
.ng-icon-whistle:before {
	content: "\f22b";
}
.ng-icon-yellow-card:before {
	content: "\f22c";
}
.ng-icon-youtube-logo:before {
	content: "\f22d";
}
