/* You can add global styles to this file, and also import other style files */
@import "reset";
@import "spinner";
@import "../../node_modules/ngx-toastr/toastr.css";
@import "../assets/generated-fonts/ng-icon.css";
@import 'animate.css';

@media print {
    .breadcrumb {
        display: none !important;
    }
}

@media only screen and (min-width: 995px) and (max-width: 1335px) {
    .responsive-fix{
    margin-left: 100px;     }
    }


ngb-pagination >.pagination li{
    display: none;
}
ngb-pagination > .pagination li:first-child{
    display: block;
}
ngb-pagination > .pagination li:last-child{
    display: block;
}
.pagination .disabled {
    cursor: not-allowed;
}

.pagelinkData .pagination .page-link{
    padding:0px !important;
}
.pagelinkData .pagination .page-link span {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.pagelinkData .pagination .page-item .page-link span {
    border-color: #f2f2f2;
    font-size: .875rem;
    transition-duration: 0.3s;
}
.pagelinkData .pagination .page-item.disabled .page-link span {
    color: #6c757d;
    pointer-events: none;
    cursor: not-allowed;
    background-color: #fff;
    border-color: #d7eafd !important;
}
.pagelinkData .pagination .page-item.active .page-link span,
.pagelinkData .pagination .page-item:hover .page-link span,
.pagelinkData .pagination .page-item:focus .page-link span,
.pagelinkData .pagination .page-item:active .page-link span {
    background: #1765b3;
    border-color: #1765b3;
    color: #ffffff;
}
.pagelinkData .pagination .page-item:hover .page-link{
    border-color:#d7eafd !important;
}



// .new-page-container{
//     position: relative;
//     box-sizing: border-box;
//     width: 375px;
//     margin: auto;
//     margin-top: 14px;
//     }

.switchcontrol .ui-widget, .ui-widget  {
    box-sizing: border-box;
    /* margin-top: 10px; */
    margin-bottom: -9px;
}
.confirm-delete .ui-widget, .ui-widget {
    box-sizing: border-box;
    /* margin-top: 10px; */
    margin-bottom: 0px !important;
}

body .ui-inputswitch .ui-inputswitch-slider {
    transition: background-color 0.2s;
    border-radius: 30px;
    background: #cccccc;
    height: 24px;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
    background: #eeeeee !important;
    margin-right: 10px !important;
    border-color: #DBDBDB;
    color: #2b2b2b;
    font-size: 15px;
    padding: 5px 20px 5px 20px;
    border: 1px solid transparent;

}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #1263b0 !important;
    padding: 5px 20px 5px 20px;
    border:1px solid #1263b0;
}

body .ui-button.ui-button-icon-only .ui-button-text {
        /* padding: 0.429em; */
        line-height: 1.3em !important;
        margin-bottom: 1px !important;
        margin-top: 1px !important;
}

.form-control {
    display: block;
    width: 100%;
    padding: -1.625rem 0.75rem;
    font-size: 1rem;
    line-height: 1;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    /* border: 1px solid #ced4da; */
    /* border-radius: 0.25rem; */
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: none;
    border-bottom: 1px solid #e8e8e8;
    font-size: 12px;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80808029;
    outline: 0;
        box-shadow: 0 0 0 0.2rem #ffffff;
}


        .btn-default {
            background-color: #eeeeee;
            border-color: #DBDBDB;
            color: #2b2b2b;
            font-size: 15px;
            padding: 5px 20px 5px 20px;
            }

        .btn-default:hover {
            color: #fff;
            background-color: #1263b0;
              border-color: #1263b0;
              border-right:1px solid #eeeeee;
              border-left:1px solid #eeeeee;
        }

        .btn-default:focus {
            box-shadow: none;
        }

        .btn-active{
            background-color: #1263b0;
            color: #fff;
            border:1px solid #1263b0;
        }

        .operationmsg {
            font-size: 16px;
            color: red;
            margin-bottom: 21px;
        }

        .success-msg {
            font-size: 16px;
            color: green;
            margin-bottom: 21px;
        }

        .inputcursor {
            padding-left: 0px;
        }

        label > input[type=radio] {
            vertical-align: middle;
            margin-top: 3px;
            // margin-bottom: -4px;
        }

        // .modal-dialog {
        //     max-width: 690px;
        //     height: 268px;
        //     margin: 1.75rem auto;
        // }

        .ui-dropdown {
            background: #ffffff;
            border: 1px solid #a6a6a6;
            transition: border-color 0.2s;
            width: 100%;
        }

        .main-card{
            width: 900px;
        }
        @media only screen and (min-width: 995px) and (max-width: 1656px) {
            .settings-card{
            margin-left: 100px;
            }
            .main-card{
                width: 600px;
            }
            .add-btn{
                margin-right: -20px;
            }
            .cevent-status{
                margin-right: 100px;
            }

            }
            .content-list-boxstyle    {
                .ui-listbox {
                    padding: 0;
                    width: 17em !important;
                    max-height: 45em !important;
                    background: #ffffff;
                    border: 1px solid #a6a6a6;
                    overflow-y: scroll;
                    font-size:12px;
                }
            }


        .ui-breadcrumb {
            border: none;
        }
        .current-event-content .card{
            border: none;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        }
        .current-event-content .table{
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        }
        .picklist .ui-picklist .ui-picklist-list .ui-picklist-item{
            color:grey;
        }


        .entries-pop-up .modal-open {
            margin: 36px 9px;
        }
        .entries-pop-up .modal-body {
            height: 400px !important;
            overflow-y: scroll;
        }



        body .ui-table .ui-table-tbody > tr > td, body .ui-table .ui-table-thead > tr > th, body .ui-paginator {
            border: 1px solid #f4f4f4;
            font-size: 12px;
        }
        body .ui-paginator .ui-dropdown{
            width: 55px;
        }
        body .ui-table .ui-table-thead > tr > th{
            background-color: #ecf3fd;
        }
        .ui-dropdown label.ui-dropdown-label {
            font-size: 12px;
        }
        .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon{
            font-size: 12px;
            margin-top: -8px;
        }

        body .ui-breadcrumb {
            border: none !important;
        }
        // Custom css wrote by Rajani on 14/11/2019 for sports create event form page
        .alert {
            font-size:12px;
            padding: 5px 9px;
        }

        .alert-danger {

            color:#ea2234 !important;
        }

        .invalid-feedback {
            margin-left: 43% !important;
        }

        .custom_label
        {
            font-size:14px;
        }

        .ui-picklist .ui-picklist-list {
            height: 24.9em !important;
        }

        .title {
            padding-top: 20px;
            padding-bottom: 50px;
        }

        body .create_event_spin .ui-spinner .ui-spinner-input {
            padding-right: 5.5em !important;
        }
        body .create_event_spin2 .ui-spinner .ui-spinner-input {
            padding-right: 25px;
        }
        .ui-multiselect {
            width: 49%;
        }

        @media only screen and (max-width: 360px) and (max-width: 1280px){
            .ui-multiselect {
                width: 87%;
            }
        }





    // @media screen and (max-width: 520px) {
    //     .new-page-container{
    //         width: 100%;
    //     }
    // }

    @media screen and (max-width: 360px) {
        .new-page-container{
            width: 100%;
        }
    }
    .createacc_dob .ui-inputtext{
        width: 280px;
    }

    .toast-top-center {
        top: 20px !important;
    }

    .register_dob .register-select {
        padding:7px !important;
        border: 1px solid #FFF;
         border-radius: 4px;
     }

 // End Rajani on 14/11/2019

.signupform .ui-radiobutton .ui-radiobutton-box {
    border: 1px solid #9e9e9e;
    background-color: #222222;
}

.signupform .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
    border: 1px solid #9e9e9e;
    background-color: #222222;
}

.signupform .ui-radiobutton .ui-radiobutton-box.ui-state-active {
    border: 1px solid #9e9e9e;
    background-color: #222222 !important;
}
.signupform .ui-chkbox .ui-chkbox-box{
    border: 1px solid #9e9e9e;
    background-color: #222222;
    width: 18px;
    height: 18px;
    border-radius: 0px;
}
.signupform .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover{
    border: 1px solid #9e9e9e;
    background-color: #222222;
}
.signupform .ui-radiobutton-label {
    color: #ffffff !important;
}


.center{
    text-align: center;
}

 @media only screen and (min-width: 995px) and (max-width: 1335px) {
        .responsive-pages-fix{
        margin-left: 100px;
        }
 }

 .rightcard .ui-card-body
 {
     padding: 26px 0px !important;
 }

 .topspace2
 {
    margin-top: 30px;
    margin-bottom: 30px !important;
 }

 .settingrightcard .ui-card-body
{
   padding: 25px 8px;
}

.notification-dropdowns .ui-dropdown label.ui-dropdown-label {
    font-size: 13px;
    font-weight: 600;
    overflow: hidden;
    width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.notification-dropdowns .ui-dropdown-panel .ui-dropdown-items {
    font-size: 13px !important;
}

/** Favorites List **/

.current-pick .ui-listbox .ui-listbox-list-wrapper {
    overflow-x: hidden;
}

.players-table .ui-table .ui-table-thead  > tr > th {
    text-align: center;
    outline: 0;
}

.players-table .ui-table .ui-table-tbody > tr > td {
    padding: 1.571em 1.857em;
}

.players-table .ui-multiselect {
    width: 92% !important;
    margin-top: 6px !important;
}

.players-table  {
    font-size: 12px !important;
}

.players-table .ui-multiselect-panel .ui-multiselect-empty-message,
   .ui-multiselect .ui-multiselect-label,
   .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
    font-size: 12px !important;
}
.players-table .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
    width: 63% !important;
}

.players-tabl .ui-multiselect .ui-multiselect-trigger {
    background: none;
}

.custom-MultiSlct .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon{
    display:none;
}

.custom-MultiSlct .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
    display:none;
}

.paymentForm .wpwl-form-card {
    color: black;
    padding: 19px 13px 0px 13px;
}

.notification-bc .ui-breadcrumb {
    margin-left: -13px !important;
}
.header-label {
    font-size: 18px;
    font-weight: 600;
}
.body-label {
    margin-top: 5px !important;
    font-size: 12px;
    font-weight: 600;

}
.ng .ui-breadcrumb{
    padding: 1px 0px !important;
}
.ng .ui-picklist.ui-picklist-responsive{
    font-size: 12px !important;
}
.ng .ui-picklist.ui-picklist-responsive .ui-picklist-buttons button{
    margin: 5px 0px !important;
}
.ng-btn{
    font-size: 10px;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    background-color: #4a90e2;
    border: 1px solid #4a90e2;
    color: white;
    cursor:pointer;
}
.ng-cancel-btn{
    font-size: 10px;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    background-color: #B73239;
    border: 1px solid #B73239;
    color: white;
    cursor:pointer;
}
.ng-add-btn{
    font-size: 10px;
    font-weight: 700;
    padding: 5px;
    text-align: center;
    background-color: rgb(32, 134, 28);
    border: 1px solid rgb(32, 134, 28);
    color: white;
    cursor:pointer;
}
.ng-participants-btn{
    font-size: 10px;
    font-weight: 700;
    padding: 8px !important;
    text-align: center;
    cursor:pointer;
}
@keyframes ui-progress-spinner-color {
    100%,0% {
        stroke: #d62d20;
    }
    40% {
        stroke: #0057e7;
    }
    66% {
        stroke: #008744;
    }
    80%,90% {
        stroke: #ffa700;
    }
}
@media (min-width: 320px) and (max-width: 520px) {

    .favoritesList .ui-sidebar-close {
        float: left;
        margin-top: 14px;
        width: 10%;
    }
    .headermenu .ui-sidebar-active {
        left: 23.99%;
    }
}

.ui-widget-content {
    color: #333;
    background: transparent;
}

.team-name{
    color: #fff;
}

.ui-widget-content .sfp-bold-family{
    color: #fff;
}

body .ui-datepicker table td {
    padding: 2px;
}
.leaguesDetailTab{
    width: 49.4%;
    text-align: center;
    font-size:12px;
    cursor: pointer;
    height: 42px;
}
#gamesPanel .ui-tabview .ui-tabview-panels{
    margin: 10px 5px
}


// .playerNotAdmin{
//     color: $white-1;
//     margin:5px 5px;
//     border-bottom: 1px solid $grey-6;
//     padding:15px 0px;
// }
.participantclass{
    line-height: 1.8rem;
    color: #fff;
}
.menuleft
{
    float: left;
    margin-right: 9px;
    margin-top: 3px;
    margin-left: 4px;
}



 .col-4 {
    flex: 0 0 33.3333333333%;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;  /* TWEENER - IE 10 */
	display: -webkit-flex; /* NEW - Chrome */
	display: flex;         /* NEW, Spec - Opera 12.1, Firefox 20+ */
    max-width: 33.3333333333%;
}

.p0
{
    padding: 0px;
}

.team-name-new
{
    width: auto;
    padding: 6px 0px 1px 0px;
}

.textright
{
    float: right;
}
.textAlignLeft{
    text-align: left;
}
.textAlignRight{
    text-align: right;
}
.bottomspace
{
    margin-bottom: 20px !important;
}
.m0
{
    margin: 0px;
}
#payment{
    top: 4px;
}


.alertcus .alert
{
    margin-left: 0px !important;
}

.ui-helper-clearfix {
    zoom: 0;
}

.routopspace
{
    margin-bottom: 10px !important;
}

.row-m {
    padding: 0px 0px;
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
}

.row {

    margin-right: -15px;
    margin-left: -15px;
}
.container-fluid-c {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    overflow-y: hidden;
}

.deskwidt
{
    width: 355px;
    margin: auto;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
    padding: 0px 0px;
}

.paddingselections
{
    background-color: transparent;
    padding: 0px 0px !important;
}

.row-mag-zero
{
    margin: 0px;
}
.regspace
{
    padding: 0px;
}

.roundspace {
    padding: 10px 0px;
}


body .pick6SelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav {
    padding: 0 0px;
}


.pick6Tabview .ui-tabview .ui-tabview-panels {
    border: none;
    padding: 10px 0px;
    border-top: 1px solid #c8c8c8;
}


body .dialogBox .ui-dialog .ui-dialog-titlebar {
    border: none;
    background-color: rgba(0, 0, 0, 0.7);

}
body .dialogBox .ui-dialog .ui-dialog-content {
    border: none;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    width: 375px;
    margin: auto;
}

body .dialogBox  .ui-dialog .ui-dialog-footer {
    border: none;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    position: relative;
    top: -1px;
    width: 375px;
    margin: auto;
}
body .dialogBox  .ui-button.ui-button-text-only .ui-button-text {
     border: none;
}
.content-list-boxstyle .ui-listbox {
    width: 25em !important;
    overflow-y: auto;
}
body .ui-listbox .ui-listbox-header {
    font-size: 16px;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
    width: calc(100% - (0.9em + 0.857em + 0.5em));
}
.ui-dropdown {
    min-width: 5.5em;
}
.ui-table table {
    table-layout: auto;
}
body .ui-dropdown .ui-dropdown-label {
    padding-right: 2.5em;
}

button.ui-button-secondary.ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-text-only.calendar-confirm-btn {
    background-color: #0e79d9;
    color: #ffffff;
    border-color: #0e79d9;
}
.ui-calendar .ui-datepicker, .pick6input .ui-calendar .ui-datepicker {
    // min-width: 100%;
    // top: 36px !important;
    // left: 0px !important;
}
@media only screen
and (min-device-width : 375px) {



    .roundSelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        padding: 7px 2px !important;
    }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        margin-right: 0px;
    }

}

@media only screen and (device-width: 768px) {

    .regspace
    {
        padding: 0px 0px;
    }
    .roundspace {
        padding: 10px 0px !important;
    }
    .new-page-container {
        width: 49% !important;
        margin: 14px auto;
    }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 0px !important;
    }
}


@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
{
    body .pick6SelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 10px;
    }
    .regspace
    {
        padding: 0px 10px;
    }
    .roundspace {
        padding: 10px 10px;
    }


    body .survivortabpick-002  .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 1px;
    }

    body .survivortabpick-001  .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 1px;
    }
    .roundSelectionsTabView-002 .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active
    {
        font-size: 11px;
    }
    .roundSelectionsTabView-002 .ui-tabview.ui-tabview-top .ui-tabview-nav li{
        font-size: 11px;
    }

    .paddingselections{
        background-color: transparent;
        padding: 0px 8px !important;
    }



    body .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 10px;
    }
    .deskwidt
    {
        width: 100%;
        margin: auto;
        padding: 0px 10px;
    }
    .ui-sidebar-right
    {
      width: 15em;
    }
    .new-page-container{

        width: 100%;

    }
    .leaguesDetailTab {
        width: 49.4%;
        text-align: center;
        font-size: 12px;
    }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        margin-right: 0px ;
    }

}
@media only screen
and (min-device-width : 411px)
and (max-device-width : 736px) {
    body .pick6SelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 10px;
    }
    .regspace
    {
        padding: 0px 10px;
    }
    .roundspace {
        padding: 10px 10px;
    }

    body .survivortabpick-001  .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 1px;
    }
    body .survivortabpick-002  .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 1px;
    }
    .paddingselections
    {
        background-color: transparent;
        padding: 0px 10px;
    }
    body .ui-tabview.ui-tabview-top .ui-tabview-nav {
        padding: 0px 10px;
    }
    .deskwidt
    {
        width: 100%;
        margin: auto;
        padding: 0px 10px;
    }
    .ui-sidebar-right
    {
      width: 17em;
    }
    .new-page-container {
        width: 100%;
    }
    .leaguesDetailTab {
        width: 49.4%;
        text-align: center;
        font-size: 12px;
    }

    body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        margin-right: 0px !important;
    }
    .roundSelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        padding: 7px 9px !important;

    }
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 14px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 14px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{font-size: 14px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 14px; }
}
input::placeholder {
    font-size: 14px !important;
  }

  ::-webkit-input-placeholder {
    font-size: 14px !important;
    }

    :-moz-placeholder {
    font-size: 14px !important;
    }

    /*--for IE10 support--*/
    :-ms-input-placeholder {
    font-size: 14px !important;
    }

    .wpwl-control input
    {
        font-size: 12px !important;
       padding: 0px !important;
       height: 13px !important;

    }

    .live-make-pick-left{
        float: left;
        width: 90%;

    }


    .live-make-pick-right{
    float: right;
    width: 10%;
    }

    @media (max-device-width: 360px) {
        .regspace
        {
            padding: 0px 10px;
        }
        .roundspace {
            padding: 10px 10px;
        }
        .deskwidt {
            width: 100%;
            margin: auto;
            padding: 0px 3px;
        }
        .paddingselections {
            background-color: transparent;
            padding: 0px 5px !important;
        }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav {
            padding: 0px 10px;
        }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
            margin-right: 0px !important;
        }
       }
    @media (max-device-width: 320px) {
        body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
            margin-right: 0px !important;
        }
        .regspace
        {
            padding: 0px 10px;
        }
        .roundspace {
            padding: 10px 5px;
        }
        .deskwidt {
            width: 100%;
            margin: auto;
            padding: 0px 3px;
        }
        .paddingselections {
            background-color: transparent;
            padding: 0px 5px !important;
        }
        body .ui-tabview.ui-tabview-top .ui-tabview-nav {
            padding: 0px 1px;
        }
        .textright {
            float: right;
            padding: 0px;
        }
        .roundSelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
            padding: 7px 2px  !important;
        }
        .selectionsullist-001 {
            width: 46.5%;
            float: left;
            height: auto;
            margin-top: 14px;
        }

        .teamselected-001 ul li, .teamselected-002 ul li {
            width: 16.6%;
            text-align: center;
            margin: 0px auto;
        }

        .ui-confirmdialog {
            top: 50%;
            left: 43%;
        }

       }

       .survivortabpick-001 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        padding: 1px 2px !important;
    }
    .survivortabpick-002 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        padding: 1px 2px !important;
    }


    .pick6SelectionsTabView-001 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        padding: 7px 2px !important;
    }
    .pick6SelectionsTabView-002 .ui-tabview.ui-tabview-top .ui-tabview-nav li {
        padding: 7px 2px !important;
    }


body .ui-widget-overlay {
    background-color: rgba(0, 0, 0, 0.85);
    }


    .ui-breadcrumb ul li a[href="#"] {
        cursor: default;
        pointer-events: none;
      }


    .ui-confirmdialog.ui-dialog .ui-dialog-content {
        padding: 34px 0px;
    }

    body .ui-button:enabled:focus {
        outline: 0 none;
        outline-offset: 0px;
        box-shadow: none;
    }

    body .pick6confirmDialogBox .ui-dialog .ui-dialog-titlebar
    {
        display: none;
    }

    .piack6BG {
        background-color: rgba(216, 216, 216, 0.15);
    }
    .pick6Teamleagues  .ui-tabview .ui-tabview-panels{
    border: none;
    background-color:transparent;
}

.tabOpacity .ui-tabview.ui-tabview-top .ui-tabview-nav li{
    background-color: rgba(216, 216, 216, 0.15);
    border: none;
   }
   @media screen and (max-width: 40em){
.ui-confirmdialog {
    width: 100%;
}
   }

   body .pick6input .create_event_spin2 .ui-spinner .ui-spinner-input {
    padding-right: 38px !important;
  }

  body .pick6input .ui-multiselect {

    width: 219px;

}

.content-list-boxstyle .current-pick-survivor .ui-listbox {
    width: 22em !important;
    overflow-y: auto;
}
.pick6input .ui-calendar.ui-calendar-w-btn .ui-inputtext {
    width: 90%;
}
.survivor-round, .survivor-round-edit {
    .ui-calendar.ui-calendar-w-btn .ui-inputtext {
        width: 67%;
    }
}
.pick6input, .survivor-round, .survivor-round-edit {
    .ui-calendar {
        display: initial;
    }
}

@media only screen and (device-width: 1440px) {
    .ui-calendar .ui-datepicker {
        // top: -65px !important;
        // left: 332px !important;
    }
    .content-list-boxstyle .current-pick-survivor .ui-listbox {
        width: 21em !important;
        overflow-y: auto;
    }
    .pick6input .ui-calendar.ui-calendar-w-btn .ui-inputtext {
        width: 88%;
        height: 34px;
        font-size: 13.5px;
    }
    .pick6input .ui-calendar {
        display: block;
    }
}

@media only screen and (device-width: 1280px) {
    .ui-calendar .ui-datepicker {
        // top: -164px !important;
        // left: 294px !important;
    }
    .pick6input .ui-calendar.ui-calendar-w-btn .ui-inputtext {
        width: 86%;
        height: 34px;
        font-size: 11.5px;
    }
    .pick6input .ui-calendar {
        display: block;
    }
    .survivor-round-edit .ui-calendar.ui-calendar-w-btn .ui-inputtext {
        height: 35px;
        font-size: 12px;
    }
}

@media only screen and (device-width: 1366px) {
    .ui-calendar .ui-datepicker {
        // top: -265px !important;
        // left: 315px !important;
    }
    .pick6input .ui-calendar.ui-calendar-w-btn .ui-inputtext {
        width: 87%;
        height: 34px;
        font-size: 12.5px;
    }
    .pick6input .ui-calendar {
        display: block;
    }
    .survivor-round-edit .ui-calendar.ui-calendar-w-btn .ui-inputtext {
        font-size: 13px;
        height: 35px;
    }
}


/* AXCESS PAYMENT CSS */
div.wpwl-container {
}
div.wpwl-label,
div.wpwl-sup-wrapper {
    width: 100%;
}
div.wpwl-wrapper>.wpwl-icon {
    right: 4px;
    top: 4px;
}
div.wpwl-wrapper {
    width: 100%;
}
div.wpwl-label {
    font-family: var(--default-font-family);
    color: var(--foreground-dark);
}
div.wpwl-group-expiry,
div.wpwl-group-brand {
    margin-top: 10px;
    width: 100%;
}
div.wpwl-group-cvv {
    width: 100%;
    color: black;
}
div.wpwl-group-cardHolder,
div.wpwl-sup-wrapper-street1,
div.wpwl-group-expiry {
    clear:both;
}
div.wpwl-sup-wrapper-street1 {
    padding-top: 1px;
}
div.wpwl-sup-wrapper-city {
    width:100%;
    margin-right:2%;
}
div.wpwl-sup-wrapper-postcode {
    width:100%;
}
div.wpwl-sup-wrapper-country {
    width: 100%;
}
div.wpwl-label-brand, div.wpwl-brand {
}
div.wpwl-group-cardNumber {
    width:100%;
    font-size: 14px;
    color: black;
}
div.wpwl-label-cardNumber {
    color: black;
}
div.wpwl-label-cvv {
    color: black;
}
div.wpwl-group-brand {
    width:100%;
    margin-top:10px;
}
div.wpwl-wrapper-brand {
    width: 66%;
    float: left;
}
div.wpwl-brand-card  {
    width: 33%;
    float: right;
}
div.wpwl-brand-custom  {
    margin: 0px 5px;
    background-image: url("https://oppwa.com/v1/paymentWidgets/img/brand.png");
}
div.wpwl-brand-MASTER {
}
div.wpwl-hint{
    font-size: 14px;
}
div.wpwl-hint-surNameError {

}
div.wpwl-sup-wrapper-state {

}
div.wpwl-label-billing {
    width: auto;
    float: left;
}
div.wpwl-wrapper-billing {
    float: left;
}

.wpwl-wrapper-submit .wpwl-button-pay {
    color: var(--button-inverted);
    background-color: transparent;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: solid 1px var(--button-inverted);
    cursor: pointer;
    font-size: 16px;
    font-family: var(--heading-font-family);
}

.wpwl-group-submit .wpwl-button-pay {
    color: var(--button-primary-color);
    background-color: var(--background-secondary);
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-family: var(--heading-font-family);
}

.wpwl-button-pay:active,
.wpwl-button-pay:focus,
.wpwl-button-pay:hover {
    color: var(--button-primary-color);
    background-color: var(--background-secondary);
}
.wpwl-button-error[disabled],
.wpwl-button-error[disabled]:hover,
.wpwl-button-error[disabled]:focus,
.wpwl-button-error[disabled]:active {
    background-color: grey;
    border-color: grey;
}

div.wpwl-sup-wrapper-state {
    display: none;
}
.wpwl-text {
    color: black;
}
.wpwl-text-billing span {
    color: var(--link-color);
}
.wpwl-form {
    margin: 0;
    max-width: 380px;
}
.wpwl-control-cardNumber,
.wpwl-control-cvv,
.wpwl-control {
    font-family: var(--default-font-family);
    font-size: 14px;
    color: black;
}

.wpwl-wrapper-registration-details {
    width: 90%;
    color: var(--foreground-dark);
}

.wpwl-group-registration.wpwl-selected {
//   border-color: var(--background-secondary);
//   background-color: var(--background-secondary);
    color:  var(--button-primary-color);
    border-radius: 4px;
    border: none;
}

.wpwl-group-registration {
    margin-bottom: 0px;
//   background-color: red; 
    color: var(--foreground-dark);
}

// Sticky element style
.sticky_fixed {
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 10;
    background-color: var(--background-default);
}

button:disabled {
    opacity: 0.4;
  }


  body {
    scrollbar-width: thin;
    scrollbar-color: rgba(0,0,0,0.3) rgba(0,0,0,0.3);

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #777;
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #000000;
      }
  }

.icon {
    line-height: initial;
}