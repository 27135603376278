:root {
  /* Colors */
  --foreground-default: #08090a;
  --foreground-secondary: #41474d;
  --foreground-tertiary: #797c80;
  --foreground-quaternary: #f4faff;
  --foreground-light: #41474d;

  --background-default: #f4faff;
  --background-secondary: #a3b9cc;
  --background-tertiary: #5c7d99;
  --background-light: #ffffff;

  --primary-default: #5dfdcb;
  --primary-dark: #24b286;
  --primary-light: #b2ffe7;

  --error-default: #ef3e36;
  --error-dark: #800600;
  --error-light: #ffcecc;

  --dark: #051629;

  /* Shadows */
  --background-tertiary-shadow: 0 1px 3px 0 rgba(92, 125, 153, 0.5);

  /* Default Font */
  --default-font: SFProText;
  // --font-barlow:'Barlow', sans-serif;


}

@font-face {
  font-family: 'Barlow';
  src: url('../assets/fonts/Barlow/Barlow-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'Barlow-SemiBold';
  src: url('../assets/fonts/Barlow/Barlow-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Barlow-Medium';
  src: url('../assets/fonts/Barlow/Barlow-Medium.ttf') format('opentype');
}

@font-face {
  font-family: 'BarlowCondensed';
  src: url('../assets/fonts/BarlowCondensed/BarlowCondensed-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'BarlowCondensed-SemiBold';
  src: url('../assets/fonts/BarlowCondensed/BarlowCondensed-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'BarlowCondensed-Medium';
  src: url('../assets/fonts/BarlowCondensed/BarlowCondensed-Medium.ttf') format('opentype');
}

@font-face {
  font-family: system-ui;
  src: local(".SFNSText-Light"), local(".HelveticaNeueDeskInterface-Light"), local(".LucidaGrandeUI"), local("Ubuntu Light"), local("Segoe UI Light"), local("Roboto-Light"), local("DroidSans"), local("Tahoma");
}

@import "../../node_modules/ngx-toastr/toastr.css";
// @import "../../node_modules/ng-pick-datetime/assets/style/picker.min.css";
// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// html {
//   overscroll-behavior: none;
// }

body {
  // overscroll-behavior: none;
  overscroll-behavior-x: none;
  // background-color: var(--background-default);
  color: var(--foreground-default);
  font-family: var(--default-font-family);
  font-size: var(--default-font-size);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &.no_scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

::-webkit-scrollbar {
  display: none;
}

/* Margin loop to support multiple margin top values -> mt-15 implies margin-top:30px; */
@for $i from 1 through 15 {
  .mt-#{$i} {
     margin-top: #{$i*2}px;
  }
}

.new-page-container {
  position: relative;
  // width: 375px;
  margin: auto;
  box-sizing: border-box;
}

.header_row {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  svg {
      // position: absolute;
      left: 0;
      fill: white;
      width: 16px;
      height: 16px;
      cursor: pointer;
      flex: 1;
  }
  p {
      text-align: center;
      font-size: 16px;
      margin: 0;
      flex: 19;
      font-weight: bold;
  }
}

/* Icon + Heading on dark background */
.h2_holder {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: var(--heading-font-family);
  margin: 20px auto;
  line-height: 1;


  svg {
    height: 18px;
    margin-bottom: 2px;
    fill: var(--foreground-default);
  }

  .heading {
    display: inline-block;
    margin-left: 11px;
    vertical-align: text-bottom;
  }
}

/*  Ligh background card for forms mostly like register/login */
.g_card_light {

  background: var(--background-light);
  color: var(--foreground-secondary);
  border-radius: 10px;
  padding: 20px;

  a {
    color: var(--button-primary-color);
    text-decoration: none; /* no underline */
  }

  h3 {
    font-family: var(--heading-font-family);
    font-size: 14px;
    text-align: center;
    color: var(--foreground-tertiary);
    margin: 0 0 var(--default-padding) 0;

  }

  p {
    margin: 20px 0 0 0;
  }
}

/* Global styling for form inputs and their fields */
.g_input_group {

  /* TODO: Make this global for other components to use */
  .icon_label_container {

    height: 16px;
    font-size: 16px;
    line-height: 16px;
    margin: 10px 0;

    svg {
      height: 16px;
    }

    label {
      vertical-align: top;
      display: inline-block;
      margin: 0 0 0 8px;
    }
  }

  label {
    font-family: var(--label-font-family);
    font-size: 16px;
    margin: 0 0 5px 0;
  }

  input {
    margin: 0;
    padding: 0 0 0 10px;
    width: 100%;
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    line-height: 38px;
  }

  select {
    width: 100%;
    height: 38px;
    padding-left: 8px;
    border: 1px solid var(--input-border-color);
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    background: url(../assets/svgs/chevron-down.svg) no-repeat right 10px top 50%;
    background-size: 10px 10px;
    background-color: #f9f9f9;
  }

  .error {
    min-height: 24px;
    padding-top: 4px;
    color: red;
  }

  ::placeholder {
    color: var(--input-border-color);
  }

  .tip {
    font-size: 13px;
  }
}

.primary_inverted_pair_btns {
  display: flex;
  margin: 10px 0 0 0;

  button {
      background: var(--background-secondary);
      flex: 1;
      min-height: 46px;
      // line-height: 46px;
      border-radius: 4px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      outline: none;
      font-family: var(--heading-font-family);
      color: var(--button-primary-color);
  }

  .inverted {
      flex: 1;
      margin-right: 10px;

      border: solid 2px var(--button-inverted-border);
      background-color: var(--button-secondary-color);
      color: var(--button-inverted);

      &.talksport {
        border: solid 2px var(--dark);
        background-color: var(--background-light);
        color: var(--dark);
      }
  }
}

/* Global primary button look and feel - No height */
.g_button_primary {
  background-color: var(--background-secondary);
  color: var(--button-primary-color);
  width: 100%;
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  text-align: center;

  .loading i {
    display: block
  }

  .idle i {
    display: none;
  }
}

.card_toast {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: rgba(0, 170, 75, 0.1);
  border-radius: 4px;
  height: 46px;
  margin-bottom: 20px;
  .icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #22b07d;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    padding: 1px;
  }
  svg {
    fill: white;
    width: 12px;
    height: 12px;
  }
  color: #22b07d;
  font-family: var(--heading-font-family);
  font-weight: bold;
  font-size: 14px;
  span {
    margin-left: 4px;
  }
}

.failed_toast {
  background-color: var(--error-light);
  color: var(--error-default);
  .icon {
    background-color: var(--error-default);
  }
}

.loading_btn {
  svg {
    position: absolute;
    fill: var(--foreground-dark);
    width: 30px;
    height: 30px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
}


.highlight {
  color: var(--foreground-highlight);
}

.bold {
  font-family: var(--heading-font-family);
  font-weight: var(--heading-font-weight);
}


/*
* modal css global
*/

.show {
  display: block;
}
.hidden {
  display: none;
}

.admin_modal {
  z-index: 10001;
  position: fixed;
  top: 30%;
  width: 100%;
  left: 0;
  // background: red;
}
.admin_modal_body {
  background: #fff;
  padding: 20px;
  // border-radius: 8px;
  border: solid 2px rgb(90, 144, 220);;
  width: 40%;
  margin: auto;
  z-index: 100001;
}

.modal_background {
  z-index: 10000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.8;
}

.app_modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    // left: calc(50vw - 191px);
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
    
    /* enables scrolling for tall modals */
    overflow: auto;

    .app_modal_body {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: transparent;
        min-height: 20px;
        min-width: 376px;
        max-width: 376px;
    }
  }

.app_modal_background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* semi-transparent black  */
    opacity: 0.05;
    
    /* z-index must be below .jw-modal and above everything else  */
    z-index: 900;
    background-image: linear-gradient(rgba(12, 22, 28, 0.8), rgba(12, 22, 28, 0.8));
    background-color: transparent;
}

input[type=number] {
  position: relative;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type=number]::-webkit-outer-spin-button, 
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  width: 15px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.swal2-title {
  font-size: 18px !important;
}

.cursor_pointer {
  cursor: pointer;
}


  .game-entry-select-dropdown {
    position: relative;
    display: inline-block;
    max-width: 100%;

  
    &__button {
      padding: 8px;
      background-color: #fff;
      border: 1px solid #cecece;
      border-radius: 8px;
      cursor: pointer;
      width: 273px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      &::focus {
        outline: none;
      }
  
      .lives_toggle {
        display: flex;
        align-items: center;


        .icon {
            line-height: 21px;
        }

        .ng-icon-heart-filled {
            color: var(--partner-color);
        }

        .ng-icon-chevron-down {
            margin-left: 8px;
        }
      }
    }
  
    &__list {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      max-height: 300px;
      overflow: auto;
      margin: 0;
      padding: 0;
      list-style-type: none;
      opacity: 0;
      pointer-events: none;
      transform-origin: top left;
      transform: scale(1,0);
      transition: all ease-in-out .3s;
      z-index: 2;
      filter: drop-shadow(0px 10px 7px rgba(0, 0, 0, 0.25));
      border-radius: 8px;

  
      &.active {
        opacity: 1;
        pointer-events: auto;
        transform: scale(1,1);
  
      }
    }
    &__list-item {
      display: flex;
      align-items: center;
      list-style-type: none;
      padding: 8px;
      background: #fff;
      border-top: 1px solid #e6e6e6;
      font-size: 14px;
      line-height: 16px;
      cursor: pointer;
      transition: all ease-in-out .3s;

      &.add_entry {
        color: #3454D1;
        .icon {
            line-height: 16px;
            margin-right: 8px;
        }
      }
      &:nth-child(even) {
        background: #F2F2F2;
      }

    }
}

.input_label_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
  }
}
